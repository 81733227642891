import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames/bind'

import { linkResolver } from 'utils/linkResolver'
import { useStore } from 'store/useStore'

import { NextPageTypes } from './NextPageTypes'
import * as s from './NextPage.module.scss'

const cn = classNames.bind(s)

const SVG = () => (
  <svg width='30' height='17' viewBox='0 0 30 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect
      x='13.2676'
      y='15.0166'
      width='21.2368'
      height='2'
      transform='rotate(-45 13.2676 15.0166)'
      fill='currentColor'
    />
    <rect
      x='1.41406'
      y='0.283203'
      width='20.5418'
      height='2'
      transform='rotate(45 1.41406 0.283203)'
      fill='currentColor'
    />
  </svg>
)

const NextPage = ({ data }: NextPageTypes) => {
  const isBottom = useStore(s => s.isBottom)
  if (!data?.document) return null
  return (
    <div className={cn('nextPage', { isActive: isBottom })} data-smooth-item>
      <Link
        to={linkResolver(data.document)}
        aria-label={`Next page: ${[...data.slug].map(c => (c !== '-' ? c : ' ')).join('')}`}
      />
      <div className={cn('arrow')}>
        <SVG />
      </div>
    </div>
  )
}

export default NextPage
