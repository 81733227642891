const isSafari = typeof document === 'undefined' ? false : navigator.vendor.includes('Apple')
const isWin = typeof document === 'undefined' ? false : navigator.platform.includes('Win')
let threshold = typeof document !== 'undefined' ? window.innerHeight : 500
typeof document !== 'undefined' && window.addEventListener('resize', () => (threshold = window.innerHeight))

const options = {
  ease: 0.07,
  scrollbar: false,
  preload: false,
  threshold,
  disableMobile: true,
  raf: true,
  vs: {
    mouseMultiplier: isSafari ? 0.35 : isWin ? 0.65 : 0.45,
    // mouseMultiplier: isSafari ? 0.35 : 0.5,
    touchMultiplier: 1.25,
    // firefoxMultiplier: 90,
    passive: true,
    limitInertia: true,
  },
}

export default options
