// extracted by mini-css-extract-plugin
export var colorBgGrey = "#f7f7f7";
export var colorBgWhite = "#fff";
export var colorBgBlue = "#406080";
export var colorSkyBlue = "#406080";
export var colorDarkBlue = "#203040";
export var colorIce = "#dae0e6";
export var colorGranite = "#b1bcc4";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var word = "AnimatedSubtitle-module--word--ktder";
export var animateIn = "AnimatedSubtitle-module--animateIn--3eSgh";
export var wordWrapper = "AnimatedSubtitle-module--wordWrapper--2edpz";