import React, { useMemo, memo, useEffect } from 'react'
import classNames from 'classnames/bind'

import { Heading } from 'components/ui/Text'

import { AnimatedTitleTypes, WordTypes, CharTypes } from './AnimatedTitleTypes'

import * as s from './AnimatedTitle.module.scss'

const cn = classNames.bind(s)

const Word = memo(({ index, word, delay }: WordTypes) => {
  return (
    <span
      className={cn('chars', { lengthy: word.length > 7 })}
      aria-hidden
      style={{ '--delay': delay } as React.CSSProperties}
    >
      {word}
    </span>
  )
})

const Char = memo(({ char, word, index, delay }: CharTypes) => {
  const correctLigature = char === 'a' && word[index + 1] === 't' ? { 'data-ligature': true } : {} // a+t ligature correct

  return (
    <span
      className={cn('char')}
      data-content={char}
      aria-hidden
      style={{ '--delay': delay } as React.CSSProperties}
      {...correctLigature}
    >
      <span>{char}</span>
    </span>
  )
})

const AnimatedTitle = ({
  text,
  skipWhiteSpace = false,
  charByChar = false,
  animateIn = false,
  callback,
  ...props
}: AnimatedTitleTypes) => {
  const words = useMemo(() => text.split(' '), [text])
  let c = 0

  useEffect(() => {
    callback && animateIn && setTimeout(callback, words.length * 165)
  }, [words, animateIn])

  return (
    <div className={cn('wrapper', { animateIn }, { skipWhiteSpace, charByChar })}>
      <Heading aria-label={text} {...props}>
        {words.map((word, i) => {
          if (!charByChar) c++
          return (
            <span className={cn('word')} key={i} aria-hidden>
              {!charByChar ? (
                <Word key={i} index={i} word={word} delay={c} />
              ) : (
                [...word].map((char, j) => {
                  c++
                  return <Char key={j} index={j} char={char} word={word} delay={c} />
                })
              )}
              {skipWhiteSpace || i === words.length - 1 ? '' : <span>{'\u00A0'}</span>}
            </span>
          )
        })}
      </Heading>
    </div>
  )
}

export default memo(AnimatedTitle)
