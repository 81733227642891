// extracted by mini-css-extract-plugin
export var colorBgGrey = "#f7f7f7";
export var colorBgWhite = "#fff";
export var colorBgBlue = "#406080";
export var colorSkyBlue = "#406080";
export var colorDarkBlue = "#203040";
export var colorIce = "#dae0e6";
export var colorGranite = "#b1bcc4";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var footer = "Footer-module--footer--2YbcH";
export var themeGrey = "Footer-module--themeGrey--nN-g1";
export var themeWhite = "Footer-module--themeWhite--3vMHM";
export var themeBlue = "Footer-module--themeBlue--bWfK6";
export var themeIce = "Footer-module--themeIce--2mGFa";
export var infos = "Footer-module--infos--145qT";
export var info = "Footer-module--info--3uL28";
export var infoCaption = "Footer-module--infoCaption--10bAv";
export var inView = "Footer-module--inView--1IQeB";
export var content = "Footer-module--content--2KLHV";