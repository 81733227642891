import React from 'react'
import classNames from 'classnames/bind'
import { useInView } from 'react-intersection-observer'

import { FooterTypes } from './FooterTypes'

import SectionTitle from 'components/ui/Slices/SectionTitle'

import * as s from './Footer.module.scss'
import { Caption } from '../Text'
const cn = classNames.bind(s)

const Footer = ({ data: { info1, info2, info3, theme, quote, author } }: FooterTypes) => {
  const infos = [info1, info2, info3]

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  return (
    <footer className={cn('footer', `theme${theme}`, { inView })} ref={ref} data-smooth-item>
      <ul className={cn('infos')}>
        {infos.map((info, i) => (
          <li key={i} className={cn('info')}>
            <span className={cn('infoCaption')}>
              <Caption>{info}</Caption>
            </span>
          </li>
        ))}
      </ul>
      <div className={cn('content')}>
        <SectionTitle slice={{ primary: { title: quote, description: author, background_color: theme } }} isFooter />
      </div>
    </footer>
  )
}

export default Footer
