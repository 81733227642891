import React, { useEffect } from 'react'
import classNames from 'classnames/bind'
import { useInView } from 'react-intersection-observer'

import { store } from 'store/store'

import * as s from './PageBottom.module.scss'
const cn = classNames.bind(s)

const PageBottom = () => {
  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    store.setState({ isBottom: inView })
  }, [inView])

  return <div aria-hidden className={cn('btm')} ref={ref} data-smooth-item />
}

export default PageBottom
