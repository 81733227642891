import React, { useEffect, useRef, useState } from 'react'
import { m, useIsPresent } from 'framer-motion'
import ScrollLock from 'react-scrolllock'
import { Helmet } from 'react-helmet'

import { store } from 'store/store'

//⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣀⣤⣤⡴⠶⠶⠶⠶⠶⠶⠶⠶⢶⣦⣤⣤⣀⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
//⠀⠀⠀⠀⠀⠀⠀⠀⠀⣀⣤⡴⠶⠛⠋⠉⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠉⠉⠙⠛⠷⠶⢦⣤⣀⡀⠀⠀⠀⠀⠀⠀⠀⠀
//⠀⠀⠀⠀⠀⠀⠀⣠⠞⠉⠀⠀⠀⢀⠀⠀⠒⠀⠀⠀⠀⠀⠒⠒⠐⠒⢒⡀⠈⠀⠀⠀⠀⡀⠒⠀⢀⠀⠀⠀⠈⠛⣦⡀⠀⠀⠀⠀⠀⠀
//⠀⠀⠀⠀⠀⢀⣾⠋⠀⠀⢀⠀⢊⠥⢐⠈⠁⠀⠀⠀⢀⠀⠀⠉⠉⠉⠀⠀⠀⠀⠀⠀⠀⠀⠈⢑⠠⢉⠂⢀⠀⠀⠈⢷⡄⠀⠀⠀⠀⠀
//⠀⠀⠀⠀⠀⣼⠃⠀⠀⠀⠀⠀⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣀⣀⣀⠀⠈⠀⠁⠀⠀⠀⠀⠈⢷⡀⠀⠀⠀⠀
//⠀⠀⠀⣠⣾⠃⠀⠀⠀⠀⠀⠀⣠⠶⠛⣉⣩⣽⣟⠳⢶⣄⠀⠀⠀⠀⠀⠀⣠⡶⠛⣻⣿⣯⣉⣙⠳⣆⠀⠀⠀⠀⠀⠀⠈⣷⣄⠀⠀⠀
//⠀⣠⠞⠋⠀⢁⣤⣤⣤⣌⡁⠀⠛⠛⠉⣉⡍⠙⠛⠶⣤⠿⠀⢸⠀⠀⡇⠀⠻⠶⠞⠛⠉⠩⣍⡉⠉⠋⠀⣈⣤⡤⠤⢤⣄⠀⠈⠳⣄⠀
//⢰⡏⠀⠀⣴⠋⠀⢀⣆⠉⠛⠓⠒⠖⠚⠋⠀⠀⠀⠀⠀⠀⠀⡾⠀⠀⢻⠀⠀⠀⠀⠀⠀⠀⠈⠛⠒⠒⠛⠛⠉⣰⣆⠀⠈⢷⡀⠀⠘⡇
//⢸⡇⠀⠀⣧⢠⡴⣿⠉⠛⢶⣤⣀⡀⠀⠠⠤⠤⠄⣶⠒⠂⠀⠀⠀⠀⢀⣀⣘⠛⣷⠀⠀⠀⠀⠀⢀⣠⣴⠶⠛⠉⣿⠷⠤⣸⠃⠀⢀⡟
//⠈⢷⡀⠄⠘⠀⠀⠸⣷⣄⡀⠈⣿⠛⠻⠶⢤⣄⣀⡻⠆⠋⠉⠉⠀⠀⠉⠉⠉⠐⣛⣀⣤⡴⠶⠛⠋⣿⠀⣀⣠⣾⠇⠀⠀⠋⠠⢁⡾⠃
//⠀⠀⠙⢶⡀⠀⠀⠀⠘⢿⡙⠻⣿⣷⣤⣀⡀⠀⣿⠛⠛⠳⠶⠦⣴⠶⠶⠶⠛⠛⠋⢿⡀⣀⣠⣤⣾⣿⠟⢉⡿⠃⠀⠀⠀⢀⡾⠋⠀⠀
//⠀⠀⠀⠈⢻⡄⠀⠀⠀⠈⠻⣤⣼⠉⠙⠻⠿⣿⣿⣤⣤⣤⣀⣀⣿⡀⣀⣀⣠⣤⣶⣾⣿⠿⠛⠋⠁⢿⣴⠟⠁⠀⠀⠀⢠⡟⠁⠀⠀⠀
//⠀⠀⠀⠀⠀⢷⡄⠀⠀⠀⠀⠙⠿⣦⡀⠀⠀⣼⠃⠉⠉⠛⠛⠛⣿⡟⠛⠛⠛⠉⠉⠉⢿⡀⠀⣀⣴⠟⠋⠀⠀⠀⠀⢠⡾⠀⠀⠀⠀⠀
//⠀⠀⠀⠀⠀⠀⠙⢦⣀⠀⣀⠀⠀⡈⠛⠷⣾⣇⣀⠀⠀⠀⠀⠀⢸⡇⠀⠀⠀⠀⢀⣀⣼⡷⠾⠋⢁⠀⢀⡀⠀⣀⡴⠋⠀⠀⠀⠀⠀⠀
//⠀⠀⠀⠀⠀⠀⠀⠀⠙⠳⣦⣉⠒⠬⣑⠂⢄⡉⠙⠛⠛⠶⠶⠶⠾⠷⠶⠚⠛⠛⠛⠉⣁⠤⢐⡨⠤⢒⣩⡴⠞⠋⠀⠀⠀⠀⠀⠀⠀⠀
//⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⠛⠶⣤⣉⠀⠂⠥⠀⠀⠤⠀⠀⠀⠀⠀⠤⠄⠀⠠⠌⠂⢈⣡⡴⠖⠋⠉⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
//⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⠛⠶⣤⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢀⣀⡴⠞⠋⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
//⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠉⠛⠳⠶⠶⠶⠶⠶⠖⠛⠋⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀

// import Scroll from 'components/core/Scroll'
// import locomotiveScroll from 'locomotive-scroll'
// import useVirtualScroll, { VirtualScrollEvent } from 'lib/useVirtualScroll'
// import SimpleSmoothScroll from 'components/core/SimpleSmoothScroll'
// import SmoothScroll from 'components/core/SmoothScroll'
// import Scrollbar from 'components/ui/Scrollbar'

import JScroll from 'lib/jsScroll'

import Footer from 'components/ui/Footer'
import PageBottom from 'components/core/PageBottom'
import NextPage from 'components/core/NextPage'

import { LayoutTypes } from './LayoutTypes'

const pageVariants = {
  initial: {
    opacity: 0,
  },
  animate: (skipTransition: boolean) => ({
    opacity: 1,
    transition: {
      opacity: {
        type: 'tween',
        duration: skipTransition ? 0 : 0.8,
      },
    },
  }),
  exit: {
    opacity: 0,
    transition: {
      opacity: {
        type: 'tween',
        duration: 0.8,
      },
    },
  },
}

const Layout = ({ children, className, footerData = null, nextPage = {}, ...props }: LayoutTypes) => {
  const layoutTransition = useRef('entering')
  const [isLayoutTransitioning, setIsLayoutTransitioning] = useState(false)
  const isPresent = useIsPresent()
  const main = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    window && window.scrollTo(0, 0) // reset scroll on route change end
    store.setState({ shouldCloseMenu: true })
  }, [])

  const onLayoutAnimationStart = () => {
    layoutTransition.current = 'exiting'
    store.getState().isTouch && setIsLayoutTransitioning(true)
    store.setState({ shouldCloseMenu: false })
  }

  const onLayoutAnimationEnd = () => {
    store.getState().isTouch && setIsLayoutTransitioning(false)
  }

  useEffect(() => {
    store.setState({ isPageTransitioning: !isPresent })
  }, [isPresent])

  useEffect(() => {
    if (!scrollRef.current) return

    // Default scroll on windows firefox
    // const isWin = typeof document === 'undefined' ? false : navigator.platform.includes('Win')
    // const isFFox = 'netscape' in window && / rv:/.test(navigator.userAgent)
    // document.documentElement.classList.toggle('is-ffox', isWin && isFFox)
    // if (isWin && isFFox) return

    // Initialize smooth scroll
    const scrollbar = new JScroll()
    scrollbar.init()
    store.setState({ scrollbar })

    // Destroy smooth scroll on page transition
    return () => {
      scrollbar?.state.initialised && scrollbar.destroy()
    }
  }, [scrollRef])

  return (
    <>
      <Helmet>
        <link rel='icon' href='/favicon/favicon.png' type='image/png' />
        <link rel='icon' href='/favicon/favicon.svg' type='image/svg+xml' />
      </Helmet>
      <ScrollLock isActive={isLayoutTransitioning} />
      <div ref={scrollRef}>
        <m.main
          ref={main}
          variants={pageVariants}
          initial='initial'
          animate='animate'
          exit='exit'
          onAnimationStart={onLayoutAnimationStart}
          onAnimationComplete={onLayoutAnimationEnd}
          custom={store.getState().isInitialLoad}
          className={className}
          {...props}
          data-smooth
        >
          {children}
          {footerData && <Footer data={footerData} />}
          {nextPage && <NextPage data={nextPage} />}
          <PageBottom />
        </m.main>
      </div>
    </>
  )
}

export default Layout
