import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'

import { SEOType } from './SEOTypes'

import thumbnail from 'assets/images/thumbnail.png'

const fallback = {
  title: 'EVEREST - First Ascent by Max Niblock and Stefan Vitasović',
  description: 'A century of expeditions to the rooftop of the world.',
  thumbnail,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const SEO = ({ title, description, thumbnail, lang = 'en' }: SEOType) => {
  const { pathname } = useLocation()
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(query)

  const metaTitle = title || fallback.title
  const metaDescription = description || fallback.description
  const metaThumbnail = siteUrl.replace(/\/$/, '') + (thumbnail || fallback.thumbnail) || ''
  const metaUrl = siteUrl.replace(/\/$/, '') + pathname

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaThumbnail,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaThumbnail,
        },
      ]}
      encodeSpecialCharacters={true}
    />
  )
}

export default SEO
