// extracted by mini-css-extract-plugin
export var colorBgGrey = "#f7f7f7";
export var colorBgWhite = "#fff";
export var colorBgBlue = "#406080";
export var colorSkyBlue = "#406080";
export var colorDarkBlue = "#203040";
export var colorIce = "#dae0e6";
export var colorGranite = "#b1bcc4";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var word = "AnimatedTitle-module--word--1aEON";
export var skipWhiteSpace = "AnimatedTitle-module--skipWhiteSpace--IviRQ";
export var charByChar = "AnimatedTitle-module--charByChar--33qDA";
export var chars = "AnimatedTitle-module--chars--2CSH0";
export var animateIn = "AnimatedTitle-module--animateIn--3fGue";
export var char = "AnimatedTitle-module--char--1Ne8T";