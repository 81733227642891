// extracted by mini-css-extract-plugin
export var colorBgGrey = "#f7f7f7";
export var colorBgWhite = "#fff";
export var colorBgBlue = "#406080";
export var colorSkyBlue = "#406080";
export var colorDarkBlue = "#203040";
export var colorIce = "#dae0e6";
export var colorGranite = "#b1bcc4";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var sectionTitle = "SectionTitle-module--sectionTitle--1llF7";
export var themeGrey = "SectionTitle-module--themeGrey--2I8R-";
export var themeWhite = "SectionTitle-module--themeWhite--VkFil";
export var themeBlue = "SectionTitle-module--themeBlue--1yaDS";
export var description = "SectionTitle-module--description--33Sda";
export var themeIce = "SectionTitle-module--themeIce--34GEL";
export var title = "SectionTitle-module--title--U0ReA";