import React, { useRef, useEffect, useState, forwardRef } from 'react'
import classNames from 'classnames/bind'
import { useWindowSize } from '@react-hook/window-size'

import { Subheading, Caption } from 'components/ui/Text'

import { AnimatedSubtitleTypes, WordTypes } from './AnimatedSubtitleTypes'

import * as s from './AnimatedSubtitle.module.scss'

const cn = classNames.bind(s)

let offsetBuffer: number = 0
let delayIncrement: number = 0

const reset = () => {
  delayIncrement = 0
  offsetBuffer = 0
}

const Word = forwardRef<HTMLDivElement, WordTypes>(({ children }, ref) => (
  <span className={cn('word')} ref={ref}>
    {children}
  </span>
))

const WordWrapper = ({ children }: WordTypes) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current) return
    if (offsetBuffer === 0 || ref.current?.offsetTop !== offsetBuffer) {
      offsetBuffer = ref.current?.offsetTop
      delayIncrement++
    }
    ref.current.style.transitionDelay = `${delayIncrement * 0.3}s`
  }, [ref])

  return (
    <span className={cn('wordWrapper')}>
      <Word ref={ref}>{children}</Word>
    </span>
  )
}

const AnimatedSubtitle = ({ text, animateIn, isFooter = false, ...props }: AnimatedSubtitleTypes) => {
  const [width] = useWindowSize({ wait: 300 })
  const [_, setNewRender] = useState(width)
  const As = isFooter ? Caption : Subheading

  useEffect(() => {
    reset()
    setNewRender(width)
  }, [width])

  return (
    <div className={cn('wrapper', { animateIn })}>
      <As aria-label={text} {...props}>
        {[...text.split(' ')].map((word, i) => (
          <WordWrapper key={i}>
            {i > 0 && '\u00A0'}
            {word}
          </WordWrapper>
        ))}
      </As>
    </div>
  )
}

export default AnimatedSubtitle
