import React, { useCallback, useState } from 'react'
import classNames from 'classnames/bind'
import { InView } from 'react-intersection-observer'

import { SectionTitleTypes } from './SectionTitleTypes'

import AnimatedTitle from 'components/ui/AnimatedTitle'
import AnimatedSubtitle from 'components/ui/AnimatedSubtitle'

import * as s from './SectionTitle.module.scss'
const cn = classNames.bind(s)

const SectionTitle = ({
  slice: {
    primary: { title, description, background_color, isMainTitle = false },
  },
  isFooter = false,
}: SectionTitleTypes) => {
  const [animateSubtitle, setAnimateSubtitle] = useState(false)

  const callback = useCallback(() => !animateSubtitle && setAnimateSubtitle(true), [])

  return (
    <section className={cn('sectionTitle', `theme${background_color}`)}>
      <div className={cn('content')}>
        <div className={cn('title')}>
          <InView triggerOnce threshold={0.5}>
            {({ inView, ref }) => (
              <div ref={ref}>
                <AnimatedTitle
                  as={isMainTitle ? 'h1' : 'h2'}
                  text={title.raw[0].text}
                  animateIn={inView}
                  callback={callback}
                />
              </div>
            )}
          </InView>
        </div>
        <div className={cn('description')}>
          <InView triggerOnce threshold={0.5}>
            {({ inView, ref }) => (
              <div ref={ref}>
                <AnimatedSubtitle
                  as={isMainTitle ? 'h2' : 'h3'}
                  text={description.raw[0].text}
                  animateIn={inView && animateSubtitle}
                  isFooter={isFooter}
                />
              </div>
            )}
          </InView>
        </div>
      </div>
    </section>
  )
}

export default SectionTitle
